@font-face {
  font-family: 'Pretendard-Light';
  font-weight: 300;
  src: url('./Pretendard-Light.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-Regular';
  font-weight: 400;
  src: url('./Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-Medium';
  font-weight: 500;
  src: url('./Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-SemiBold';
  font-weight: 600;
  src: url('./Pretendard-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-Bold';
  font-weight: 700;
  src: url('./Pretendard-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-ExtraBold';
  font-weight: 800;
  src: url('./Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat-Light';
  font-weight: 300;
  src: url('./Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  font-weight: 400;
  src: url('./Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  font-weight: 500;
  src: url('./Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  font-weight: 600;
  src: url('./Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Bold';
  font-weight: 700;
  src: url('./Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  font-weight: 800;
  src: url('./Montserrat-ExtraBold.ttf') format('truetype');
}
